<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
    <!-- 유정 및 체적환산 관리 --------------------------------------------------------------------------------------------->

      <BCard header-tag="header" footer-tag="footer">
        <div slot="header">
          <BIconDropletFill/> <strong> 저장유종 설정 </strong>
          <div class="card-header-actions">
            <small class="text-muted">유종,색상 등의 정보 및 온도별 체적 환산 비율을 관리합니다.</small>
          </div>
        </div>


        <BRow>
          <BCol lg="6">
            <oil-list/>
          </BCol>
          <BCol lg="6">
            <oil-temp-list :group-index="groupIndex" :key="oilTempKey"/>
          </BCol>
        </BRow>

      </BCard>


  </div>
</template>

<style src="spinkit/spinkit.min.css"></style> <!--animated processing icon-->

<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall,
  cloneVar,
} from '../../common/utils';
import {ExcelEditorLabel} from "@/common/constants";
import OilList from '../components/OilList'
import OilTempList from '../components/OilTempList'
// import qs from 'querystring';
// import moment from "moment";



const _oil = {
  code           : '',
  name           : '',
  oilTempGroup   : '',
  expansionRate  : '',
  density        : '',
  prdCode        : '',
  color          : '',
  stockpile      : '',
  dayConsumption : '',
};



const oilColMap = {
  code           : '유종코드',
  name           : '유종명',
  oilTempGroup   : '비중그룹',
  expansionRate  : '팽창계수',
  density        : '기준밀도',
  prdCode        : '상품코드',
  color          : '색상정보',
  stockpile      : '비축량',
  dayConsumption : '1일 소모량',

};


//----------------------------------------------------------------------------------------------------
export default {
  name: "Oil",
  components: {
    OilList,
    OilTempList
  },

  computed: {},

  data () {
    return {
      groupIndex: null,
      oilTempKey: 1,
      isSiteSaving: false,
      maps: this.$store.state.codeMaps,
      oil: cloneVar(_oil),
      accountOpts: [
        {value: '', label: '계정 선택'},
        {value: 'smartpol', label: '[smartpol] 시스템 관리자'}
      ],
      accountMap: {
        smartpol : '시스템관리자'
      },
      editorLabel: ExcelEditorLabel,
      selectedGroup: null,
      arCodeMap  : {},
      arCodeOpts  : [],
      downloadReason: '',
      searchWord : '',
      progCount: 0,
      totalCount: 0,
      rows: [],

      paramMap: {},
      numOfRecord: 10,
      siteFields: {

      },
      modalTitle: '',
      tankFormShow: false,

    }

  },
  async created(){
    try{
      //
    }catch(err){
      console.log(err);
    }
  },


  mounted() {
    console.log("--- site mounted---------------------");
    // below is not work!
  },

  methods: {

    async getGroupList(){
      try{

        this.rows = [];
        const r = await apiCall('get', `/api/workgroup`);

        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`${this.rows.length}건 조회됨`, 'info');
          // console.log( 'task-data-query-result--->', r.result  );
        }

      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }
    },

    async selectRecord(idx, evt){
      if(!evt){
        this.selectedGroup = null;
        return;
      }

      this.selectedGroup = this.$refs['excelGrid'].getSelectedRecords()[0];

    },

    setGroup(groupIndex){
      console.log('at parent --- setGroup--->', groupIndex);
      this.groupIndex = groupIndex;
      this.oilTempKey++;
    },



  }
}
</script>
